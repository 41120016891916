/* Modal background overlay */
.modal {
    display: block; /* Ensures visibility */
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1050; /* Ensure it is above other elements */
    overflow-x: hidden;
    overflow-y: auto;
  }
  
  /* Modal dialog */
  .modal-dialog {
    position: relative;
    margin: 1.75rem auto;
    max-width: 500px; /* Adjust width as needed */
  }
  
  /* Modal content */
  .modal-content {
    background-color: #fff; /* White background for the modal */
    border-radius: 0.5rem; /* Rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Soft shadow for depth */
    overflow: hidden; /* Ensure proper clipping */
  }
  
  /* Modal header */
  .modal-header {
    padding: 1rem;
    border-bottom: 1px solid #dee2e6;
    background-color: #007bff; /* Blue background */
    color: white; /* White text */
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  /* Modal body */
  .modal-body {
    padding: 1rem;
    background-color: #f9f9f9; /* Light gray background */
    color: black;
  }
  
  /* Close button in modal */
  .btn-close {
    background: none;
    border: none;
    font-size: 1.5rem;
    line-height: 1;
    color: #080101;
    cursor: pointer;
  }
  
  /* Form elements inside modal */
  .modal-body input,
  .modal-body textarea {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #505253;
    border-radius: 0.25rem;
    background-color: #ffffff;
    color: black !important;
  }
  
  /* Form button */
  .modal-body .btn {
    width: 100%;
    padding: 0.5rem;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
  }
  
  .modal-body .btn:hover {
    background-color: #218838;
  }
  